export const useTranslations = () => {
  const { t } = useI18n()

  // This function is used to translate strings, handle empty or undefined values
  const translate = (...args) => {
    const translationArgs = args.filter((arg) => arg)
    if (translationArgs?.length === 0) {
      return ''
    }

    return t(...translationArgs)
  }
  return {
    translate,
  }
}
